import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useLocation, useNavigate, useSearchParams } from "react-router";

import { VStack } from "@chakra-ui/react";
import { ResendMfaLink } from "@equidefi/portals/components/ResendMfaLink";
import { useWorkflowLogin } from "@equidefi/portals/hooks/useAuthentication";
import { BackButton, Input, Text } from "@equidefi/ui";

import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";
import { useWorkflowRegister } from "../../../hooks/useWorkflowRegister";
import { useWorkflowContext } from "../context/WorkflowContext";
import { WorkflowContainer } from "../WorkflowContainer";
import WorkflowForm from "../WorkflowForm";

const WorkflowMFA = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { offering } = useWorkflowContext();

  const workflowRegister = useWorkflowRegister();
  const workflowLogin = useWorkflowLogin(offering?.id);
  const [mfaCode, setMfaCode] = useState(searchParams.get("code"));
  const fromEmailContent = searchParams.get("email") && searchParams.get("code");

  const location = useLocation();

  useDocumentTitle("Multi-Factor Authentication");

  const onSubmit = async (e) => {
    e.preventDefault();
    const email = location.state?.email || searchParams.get("email");
    await workflowRegister.onSubmitMFA(email, mfaCode);
  };

  useEffect(() => {
    if (
      location.state &&
      (!location.state?.password || !location.state?.email)
    ) {
      toast({
        status: "error",
        description: "Something went wrong. Please login again.",
      });
      navigate("/");
    }
  }, [location, navigate]);

  return (
    <WorkflowContainer
      title="Two Factor Authentication"
      buttonProps={{
        isDisabled: mfaCode?.length !== 6,
        isLoading: workflowRegister.isLoading,
      }}
      onContinue={onSubmit}
      buttonLabel="Continue"
    >
      <Text>
        Your account is protected with two-factor authentication. We've sent you
        an email. Please enter the code below.
      </Text>
      <WorkflowForm onSubmit={onSubmit}>
        <VStack>
          {
            !fromEmailContent && (
              <ResendMfaLink authFunction={workflowLogin} state={location.state} />
            )
          }
          <Input
            as={NumberFormat}
            name="mfa_code"
            format="######"
            mask="_"
            placeholder="0 0 0 0 0 0"
            value={mfaCode}
            onValueChange={({ value }) => setMfaCode(value)}
            autoComplete="off"
            h="12"
            textAlign="center"
            mb="3"
          />

          <BackButton
            textAlign="center"
            text="Back to login"
            onClick={() => navigate(`/offerings/${offering.slug}/registration`)}
          />
        </VStack>
      </WorkflowForm>
    </WorkflowContainer>
  );
};

export default WorkflowMFA;
