import { useEffect } from "react";
import { useWorkflowContext } from "../pages/workflow/context/WorkflowContext";

export default function useWorkflowPosition(index) {
  const { setPosition } = useWorkflowContext();

  useEffect(() => {
    setPosition(index);
  }, []);
}
