import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";
import useWorkflowPosition from "../../../hooks/useWorkflowPosition";
import { useWorkflowContext } from "../context/WorkflowContext";
import InvestmentAgreements from "./InvestmentAgreements";

export const WorkflowAgreements = () => {
  const { investment } = useWorkflowContext();
  useWorkflowPosition(7);

  useDocumentTitle([
    "Subscription Agreement",
    investment?.offering?.name,
    investment?.issuer?.name,
  ]);

  return (
    <InvestmentAgreements
      investment={investment}
      investmentAgreements={investment?.investment_agreements}
    />
  );
};
