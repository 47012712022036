import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Navigate } from "react-router";
import * as Yup from "yup";

import { PhoneField } from "@equidefi/portals/components/Auth";
import { Input } from "@equidefi/ui/forms";

import
  {
    useCurrentUser,
    useUpdateCurrentUser,
  } from "@equidefi/portals/hooks/useUsers";
import { MESSAGE } from "../../../../../issuer/src/constants/forms";
import { useEventDispatcher } from "../../../hooks/useInvestments";
import useWorkflowPosition from "../../../hooks/useWorkflowPosition";
import { WorkflowContainer } from "../WorkflowContainer";
import WorkflowForm from "../WorkflowForm";
import { useWorkflowContext } from "../context/WorkflowContext";

const schema = Yup.object().shape({
  first_name: Yup.string()
    .trim()
    .nullable()
    .min(2, "First name must be at least 2 characters")
    .required(MESSAGE.REQUIRED("First name")),
  middle_name: Yup.string().trim().nullable(),
  last_name: Yup.string()
    .trim()
    .nullable()
    .min(2, "Last name must be at least 2 characters")
    .required(MESSAGE.REQUIRED("Last name")),
  phone: Yup.string()
    .nullable()
    .required()
    .test({
      name: "phone",
      message: "Must be a valid phone number",
      test: (value) => (!!value ? isValidPhoneNumber(value) : false),
    }),
});

const DEFAULT_DATA = {
  first_name: null,
  middle_name: null,
  last_name: null,
  phone: null,
};

export function WorkflowProfile() {
  const { investment, offering, refetchInvestment } = useWorkflowContext();
  useWorkflowPosition(1);

  const dispatchEvent = useEventDispatcher();
  const update = useUpdateCurrentUser();
  const { data: me, refetch: refetchUser } = useCurrentUser();
  const toast = useToast();
  const [spinner, showSpinner] = useState(false);

  // NOTE: navigate() was not working and for unknown reasons could not be
  // forced. Switching to a use of <Navigate /> to force this behavior
  const [isSubmitted, setSubmitted] = useState(false);

  const onSubmit = async (data) => {
    showSpinner(true);
    try {
      await update.mutateAsync({
        first_name: data.first_name?.trim(),
        middle_name: data.middle_name?.trim() || null,
        last_name: data.last_name?.trim(),
        phone: data.phone,
      });

      await Promise.allSettled([
        refetchUser(),
        dispatchEvent.mutateAsync({
          event: "PROFILE",
          action: "SUBMITTED",
          investmentId: investment.id,
          actor: { user: investment.user_id },
        }),
      ]);

      await refetchInvestment();

      setSubmitted(true);
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        description: "There was an error trying to save your profile data",
      });
      showSpinner(false);
    }
  };

  const {
    handleSubmit,
    setFieldValue,
    handleBlur,
    getFieldProps,
    errors,
    touched,
    isValid,
  } = useFormik({
    initialValues: me || DEFAULT_DATA,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  if (isSubmitted) {
    return (
      <Navigate
        to={`/offerings/${offering.slug}/${investment.id}/subscription`}
      />
    );
  }

  return (
    <WorkflowContainer
      title="Investor Information"
      onContinue={handleSubmit}
      buttonProps={{
        isDisabled: !isValid,
        mt: 2,
        isLoading: spinner,
      }}
    >
      <WorkflowForm onSubmit={handleSubmit}>
        <Input
          label="First Name"
          placeholder="e.g. John"
          isRequired
          error={touched.first_name && errors.first_name}
          isInvalid={errors.first_name && touched.first_name}
          {...getFieldProps("first_name")}
        />
        <Input
          label="Middle Name"
          placeholder="e.g. David"
          error={touched.middle_name && errors.middle_name}
          isInvalid={errors.middle_name && touched.middle_name}
          {...getFieldProps("middle_name")}
        />
        <Input
          label="Last Name"
          placeholder="e.g. Smith"
          isRequired
          error={touched.last_name && errors.last_name}
          isInvalid={errors.last_name && touched.last_name}
          {...getFieldProps("last_name")}
        />
        <PhoneField
          handleBlur={handleBlur}
          error={touched?.phone && errors?.phone}
          setFieldValue={setFieldValue}
          {...getFieldProps("phone")}
        />
      </WorkflowForm>
    </WorkflowContainer>
  );
}
