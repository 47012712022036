import { useToast, ButtonGroup, Card } from "@chakra-ui/react";
import { useSubmitPreApproved } from "@equidefi/portals/hooks/useAccreditation";
import { Text } from "@equidefi/ui";
import React, { useContext } from "react";
import { WorkflowContainer } from "../WorkflowContainer";
import { useWorkflowContext } from "../context/WorkflowContext";
import { WorkflowButton } from "../../../components/workflow/WorkflowButton";

export const AccreditationSelect = ({ onReupload, navigateToVault }) => {
  const { investment } = useWorkflowContext();
  const toast = useToast();

  const submitPreApproved = useSubmitPreApproved(investment?.id);

  const handleSubmit = async () => {
    try {
      await submitPreApproved.mutateAsync();
      navigateToVault();
    } catch (e) {
      console.error(e);
      toast({
        status: "error",
        description: "Failed to submit your files",
      });
    }
  };

  return (
    <WorkflowContainer title="Accreditation Documents">
      <Text>
        Your investment already has accreditation documents that have been
        approved!
      </Text>
      <Card p={4} mt="10">
        <Text textAlign="center">
          Would you like to complete your investment with pre-approved
          accreditation documents?
        </Text>
        <ButtonGroup my="3" gap="2">
          <WorkflowButton
            flexShrink={{ base: "2", md: 1 }}
            bg="secondary"
            onClick={onReupload}
          >
            Reupload
          </WorkflowButton>
          <WorkflowButton flexShrink="1" onClick={handleSubmit}>
            Complete Investment
          </WorkflowButton>
        </ButtonGroup>
      </Card>
    </WorkflowContainer>
  );
};
