import { Stack } from "@chakra-ui/react";
import { trackEvent } from "@intercom/messenger-js-sdk";
import { useEffect, useLayoutEffect, useState } from "react";
import { useCookies } from "react-cookie";
import
  {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
  } from "react-router";
import { validate } from "uuid";

import { identify as identifyFullStory } from "@equidefi/portals/helpers/fullstory";
import { useAuth } from "@equidefi/portals/hooks/useAuth";
import { offeringIsCompleted } from "@equidefi/shared";

import NavBar from "@/components/nav/NavBar";
import { useUserInvestments } from "@/hooks/useInvestments";

import { useWorkflowContext } from "./context/WorkflowContext";
import LoadingView from "./WorkflowLoadingView";

const LOADING_TIME = 2250;

const buildOfferingUrl =
  (slug) =>
  (...pieces) =>
    `/offerings/${slug}/${pieces.filter(Boolean).join("/")}`;

const WorkflowLayout = ({ children }) => {
  const { isLoading, investment, startUri, position, isError, user, offering } =
    useWorkflowContext();
  const { isLoggedIn } = useAuth();
  const { slug, investment_id: investmentId } = useParams();
  const { data: offeringInvestments } = useUserInvestments(
    { offering_id: offering?.id },
    { enabled: isLoggedIn && !!offering?.id, refetchOnMount: "always" }
  );
  const isValidUuid = validate(investmentId);

  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const [, setCookie] = useCookies(["bcode"]);
  const [delay, setDelay] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Purely for visual purposes to simulate loading the offering
      setDelay(false);
    }, LOADING_TIME);

    return () => clearTimeout(timeoutId);
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [position, location.pathname]);

  useEffect(() => {
    const fullstoryUrl = identifyFullStory(user);
    if (fullstoryUrl) {
      trackEvent("fullstory-session", {
        "Session Link": { url: fullstoryUrl, value: "Play in FullStory" },
      });
    }
  }, [user]);

  useEffect(() => {
    let redirectUrl;
    const offeringUrl = buildOfferingUrl(slug);

    // MFA Code
    const code = searchParams.get("code");

    // Broker tracking code
    const bcode = searchParams.get("bcode");

    // If there is a code, we need to bypass the check and go to the MFA page as expected
    if (code) {
      return () => {};
    }

    if (bcode) {
      setCookie("bcode", bcode, { path: "/", maxAge: 30758480 }); // set broker code cookie, expires in 1 year
    }

    if (searchParams.get("payment_intent")) {
      return () => {};
    }

    // If there is an investmentId is in the params, make sure investment is loaded before proceeding
    // If there is no investmentId, we can just execute the below code.
    if (investmentId && isLoading) {
      return () => {};
    }

    if (!slug) {
      // Unlikely, but will guard against not having a slug available
      redirectUrl = "/";
    } else if (offeringIsCompleted(offering)) {
      // If offering completed, then redirect to investor vault
      redirectUrl = "/vault/dashboard";
    } else if (!isLoggedIn) {
      // If user token is missing or invalid (expired), go to registration/login screen
      if (location.pathname.includes("mfa")) {
        redirectUrl = offeringUrl("mfa");
      } else {
        redirectUrl = offeringUrl("registration");
      }
    } else if (!investmentId) {
      if (offeringInvestments?.length === 0) {
        redirectUrl = offeringUrl("registration");
      } else if (offeringInvestments?.length > 0) {
        redirectUrl = offeringUrl("choose");
      }
    } else if (investmentId && startUri) {
      // Everything is loaded for investment, so go to correct workflow step
      redirectUrl = offeringUrl(investmentId, startUri);
    }

    if (redirectUrl && redirectUrl !== location.pathname) {
      navigate(redirectUrl, { replace: true });
    }
  }, [
    offering,
    slug,
    investmentId,
    navigate,
    searchParams,
    offeringInvestments,
  ]);

  if (delay || !offering || (isValidUuid && (!investment || isLoading))) {
    return <LoadingView isError={isError} />;
  }

  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      as="main"
      minH="100vh"
      spacing={0}
      bgColor="background"
    >
      <NavBar offering={offering} position={position}>
        {children}
      </NavBar>
    </Stack>
  );
};

export default WorkflowLayout;
