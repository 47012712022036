import { Outlet } from "react-router";
import { WorkflowProvider } from "./context/WorkflowContext";
import WorkflowLayout from "./WorkflowLayout";

const WorkflowRoot = () => {
  return (
    <WorkflowProvider>
      <WorkflowLayout>
        <Outlet />
      </WorkflowLayout>
    </WorkflowProvider>
  );
};

export default WorkflowRoot;
